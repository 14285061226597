import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BlindsProducts from '../Blinds/BlindsProducts';
// import Blinds_pro02 from '../../images/Blinds-02.jpg'
import Blinds_pro07 from '../../images/Blinds-07.jpg'
import Blinds_pro08 from '../../images/Blinds-08.jpg'
import Blinds_pro09 from '../../images/Blinds-09.jpg'
import Blinds_pro10 from '../../images/Blinds-10.jpg'
import Blinds_pro11 from '../../images/Blinds-11.jpg'
import Blinds_pro12 from '../../images/Blinds-12.jpg'
import Blinds_pro13 from '../../images/Blinds-13.jpg'
import Blinds_pro14 from '../../images/Blinds-14.jpg'
import Blinds_pro15 from '../../images/Blinds-15.jpg'
import Blinds_pro16 from '../../images/Blinds-16.jpg'



import darkBanner from "../../images/dark-img.jpg";



const Blinds = () => {

    const blindsImages = [
       
        // {
        //     img:Blinds_pro02,
        //     title:"Blinds 2"

        //   },

          {
            img:Blinds_pro07,
            title:"Blinds 7"
          },
          {
            img:Blinds_pro08,
            title:"Blinds 8"

          },
           {
            img:Blinds_pro09,
            title:"Blinds 9"
          },
          {
            img:Blinds_pro10,
            title:"Blinds 10"

          },
          {
            img:Blinds_pro11,
            title:"Blinds 11"
          },
          {
            img:Blinds_pro12,
            title:"Blinds 12"
          },
          {
            img:Blinds_pro13,
            title:"Blinds 12"
          },
          {
            img:Blinds_pro14,
            title:"Blinds 12"
          },
          {
            img:Blinds_pro15,
            title:"Blinds 15"
          },
          {
            img:Blinds_pro16,
            title:"Blinds 16"
          },






      ]
    useEffect(() => {
        document.title = "Blinds - bharat furnishings";
    }, []);

    return (
    <React.Fragment>
    <Container fluid className='px-4 px-lg-5'>
                <Row>
                <Col lg="12" className='custom_slider inner_banner position-relative'>
                        <img src={darkBanner} alt="About" className='img-fluid w-100' />
                        <div className="position-absolute top-50 start-50 translate-middle ">
                            <h1 className="text-white text-center">Blinds </h1>
                           
                        </div>

                    </Col>

                <Col lg={12}>
                <nav aria-label="breadcrumb" className=" mt-4 mb-4 ">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> <FaHome className=' lh-sm pb-1'/>  Home</Link></li>
                                    <li className="breadcrumb-item  text-dark " aria-current="page">Blinds</li>
                                </ol>
                            </nav>
                </Col>

                <BlindsProducts blindsImages={blindsImages}/>
                </Row>




 











            </Container>








        </React.Fragment>
    )
}

export default Blinds
