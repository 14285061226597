import React, { useEffect } from 'react'
import './Wallpaper.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FabricProduct from '../Wallpaperproducts/Wallpaperproducts';
import darkBanner from "../../images/dark-img.jpg";

import wallpaper01 from '../../images/wallpaper--01.jpg'
import wallpaper02 from '../../images/wallpaper--02.jpg'
import wallpaper03 from '../../images/wallpaper--03.jpg'
import wallpaper04 from '../../images/wallpaper--04.jpg'
import wallpaper05 from '../../images/wallpaper--05.jpg'
import wallpaper06 from '../../images/wallpaper--06.jpg'
import wallpaper07 from '../../images/wallpaper--07.jpg'
import wallpaper09 from '../../images/wallpaper--09.jpg'
import wallpaper10 from '../../images/wallpaper--10.jpg'
import wallpaper11 from '../../images/wallpaper--11.jpg'
import wallpaper12 from '../../images/wallpaper--12.jpg'
import wallpaper13 from '../../images/wallpaper--13.jpg'
import wallpaper14 from '../../images/wallpaper--14.jpg'
import wallpaper15 from '../../images/wallpaper--15.jpg'
import wallpaper16 from '../../images/wallpaper--16.jpg'
import wallpaper17 from '../../images/wallpaper--17.jpg'
import wallpaper18 from '../../images/wallpaper--18.jpg'
import wallpaper19 from '../../images/wallpaper--19.jpg'
import wallpaper20 from '../../images/wallpaper--20.jpg'
import wallpaper21 from '../../images/wallpaper--21.jpg'
import wallpaper22 from '../../images/wallpaper--22.jpg'


import wallpaper23 from '../../images/wallpaper--23.jpg'
import wallpaper24 from '../../images/wallpaper--24.jpg'
import wallpaper25 from '../../images/wallpaper--25.jpg'
import wallpaper26 from '../../images/wallpaper--26.jpg'
import wallpaper27 from '../../images/wallpaper--27.jpg'




const Wallpaper = () => {

    const galleryImages = [
        {img:wallpaper16,title:"Fabrick 16"},
        {img:wallpaper17,title:"Fabrick 17"},
        {img:wallpaper18,title:"Fabrick 18"},
        {img:wallpaper19,title:"Fabrick 19"},
        {img:wallpaper01,title:"Fabrick 1"},
        {img:wallpaper02,title:"Fabrick 2"},
          {img:wallpaper03,title:"Fabrick 3"},
          {img:wallpaper04,title:"Fabrick 4"},
           {img:wallpaper05,title:"Fabrick 5"},
          {img:wallpaper06,title:"Fabrick 6"},
          {img:wallpaper07,title:"Fabrick 7"},
           {img:wallpaper09,title:"Fabrick 9"},
          {img:wallpaper10,title:"Fabrick 10"},
          {img:wallpaper11,title:"Fabrick 11"},
          {img:wallpaper12,title:"Fabrick 12"},
          {img:wallpaper13,title:"Fabrick 13"},
          {img:wallpaper14,title:"Fabrick 14"},
          {img:wallpaper15,title:"Fabrick 15"},
 
          {img:wallpaper20,title:"Fabrick 20"},
          {img:wallpaper21,title:"Fabrick 21"},
          {img:wallpaper22,title:"Fabrick 22"},

          {img:wallpaper23,title:"Fabrick 22"},
          {img:wallpaper24,title:"Fabrick 22"},
          {img:wallpaper22,title:"Fabrick 22"},
          {img:wallpaper25,title:"Fabrick 22"},
          {img:wallpaper26,title:"Fabrick 22"},
          {img:wallpaper27,title:"Fabrick 22"},

      ]



    useEffect(() => {
        document.title = "Wallpaper - bharat furnishings";
    }, []);



  return (
    <React.Fragment>
    <Container fluid className='px-4 px-lg-5'>
                <Row>
                <Col lg="12" className='custom_slider inner_banner position-relative'>
                        <img src={darkBanner} alt="About" className='img-fluid w-100 bg-dark' />
                        <div className="position-absolute top-50 start-50 translate-middle ">
                            <h1 className="text-white text-center">Wallpaper </h1>
                           
                        </div>

                    </Col>

                <Col lg={12}>
                <nav aria-label="breadcrumb" className=" mt-4 mb-4 ">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> <FaHome className=' lh-sm pb-1'/>  Home</Link></li>
                                    <li className="breadcrumb-item  text-dark " aria-current="page">Wallpaper</li>
                                </ol>
                            </nav>
                </Col>

                <FabricProduct galleryImages={galleryImages}/>
                </Row>




 











   </Container>
  </React.Fragment>
  )
}

export default Wallpaper
