import React from 'react'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

const Address = (props) => {

    const { title, disc } = props.item
    return (
        <React.Fragment>
            <CardGroup style={{ minHeight: 150 }}>
                <Card className='me-3'>


                    <Card.Body>
                        <Card.Title className='fw-bold'><h2>{title}</h2></Card.Title>
                        <Card.Text>
                            {disc}
                        </Card.Text>
                    </Card.Body>

                </Card>





            </CardGroup>

        </React.Fragment>
    )
}

export default Address
