import React, { useEffect } from 'react'
import './Rugs.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FabricProduct from '../FabricProduct/FabricProduct';
import darkBanner from "../../images/dark-img.jpg";

import rugs_pro01 from '../../images/rugs-01.jpg'
import rugs_pro02 from '../../images/rugs-02.jpg'
import rugs_pro03 from '../../images/rugs-03.jpg'
import rugs_pro04 from '../../images/rugs-04.jpg'
import rugs_pro05 from '../../images/rugs-05.jpg'
import rugs_pro06 from '../../images/rugs-06.jpg'
import rugs_pro07 from '../../images/rugs-07.jpg'
import rugs_pro08 from '../../images/rugs-08.jpg'
import rugs_pro09 from '../../images/rugs-09.jpg'
import rugs_pro10 from '../../images/rugs-10.jpg'


const Rugs = () => {

    const galleryImages = [
        {
          img:rugs_pro01,
          title:"Fabrick 1"
        },
        {
            img:rugs_pro02,
            title:"Fabrick 2"

          },
          {
            img:rugs_pro03,
            title:"Fabrick 3"
          },
          {
            img:rugs_pro04,
            title:"Fabrick 4"

          },
           {
            img:rugs_pro05,
            title:"Fabrick 5"

          },
          {
            img:rugs_pro06,
            title:"Fabrick 6"

          },
          {
            img:rugs_pro07,
            title:"Fabrick 7"

          },
          {
            img:rugs_pro08,
            title:"Fabrick 8"

          },
           {
            img:rugs_pro09,
            title:"Fabrick 9"

          },
          {
            img:rugs_pro10,
            title:"Fabrick 10"

          },

      ]

    useEffect(() => {
        document.title = "Fabric - bharat furnishings";
    }, []);


    return (
        <React.Fragment>
        <Container fluid className='px-4 px-lg-5'>
                    <Row>
                    <Col lg="12" className='custom_slider inner_banner position-relative'>
                            <img src={darkBanner} alt="About" className='img-fluid w-100' />
                            <div className="position-absolute top-50 start-50 translate-middle ">
                                <h1 className="text-white text-center">Rugs </h1>
                            </div>
                        </Col>
    
                    <Col lg={12}>
                    <nav aria-label="breadcrumb" className=" mt-4 mb-4 ">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"> <FaHome className=' lh-sm pb-1'/>  Home</Link></li>
                                        <li className="breadcrumb-item  text-dark " aria-current="page">Rugs</li>
                                    </ol>
                                </nav>
                    </Col>
    
                    <FabricProduct galleryImages={galleryImages}/>
                    </Row>
    
    
    
    
     
    
    
    
    
    
    
    
    
    
    
    
       </Container>
      </React.Fragment>
    )
}

export default Rugs
