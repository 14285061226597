import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import darkBanner from "../../images/dark-img.jpg";
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import sitemapImg from '../../images/sitemap.jpg'



const Sitemap = () => {
    useEffect(() => {
        document.title = "Sitemap - bharat furnishings";
    }, []);


    return (
        <React.Fragment>
            <Container fluid className='px-4 px-lg-5'>
                <Row className='d-flex align-items-center'>
                <Col lg="12" className='custom_slider inner_banner position-relative mb-5'>

<img src={darkBanner} alt="About" className='img-fluid w-100' />

<div className="position-absolute top-50 start-50 translate-middle ">
    <h2 className="text-white text-center">Sitemap </h2>
    <nav aria-label="breadcrumb" className="d-none d-lg-block">
        <ol className="breadcrumb text-white align-items-center  ">
            <li className="breadcrumb-item ">
            <Link to="/"> <FaHome className=' lh-sm pb-1'/> </Link></li>
            <li className="breadcrumb-item active " aria-current="page">Sitemap</li>
        </ol>
    </nav>
</div>

</Col>



                    <Col md="6">


                        <ul className='fs-4'>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/About">About</NavLink></li>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/Fabric">Fabric</NavLink></li>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/Wallpaper">Wallpaper</NavLink></li>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/Bed-Bath-Linen">Bed & Bath Linen</NavLink></li>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/Blinds">Blinds</NavLink></li>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/Rugs">Rugs</NavLink></li>
                            <li><NavLink className="px-3 lh-lg" runat="server" to="/Contact">Contact</NavLink></li>
                        </ul>



                    </Col>

                    <Col md="6">
                        <img src={sitemapImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Sitemap
