import React, { useEffect, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import SliderOne from "../../images/slider-01.jpg";
import SliderTwo from "../../images/slider-02.jpg";

import SliderThree from "../../images/slider-03.jpg";
import SliderFour from "../../images/slider-04.jpg";
import SliderFive from "../../images/slider-05.jpg";


import FabricImg from "../../images/Fabric-home.jpg";
import WallpaeprImg from "../../images/home-wallpaper.jpg"


import BedBathLinen from "../../images/Ben_Bath_Linen-05.jpg";
import BlindsLinen from "../../images/Blinds-home.jpg";
import RugsImg from "../../images/Rugs-home.jpg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import '../Home/Home.css';
import AboutImage from "../../images/about-02.jpg";
import ImageSlider from './ImageSlider';
import { Link } from 'react-router-dom';
// import Testimonials from './Testimonials';
const Home = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
};

  useEffect(() => {
    document.title = "Home - bharat furnishings";

  }, []);

  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
      <Container fluid className='px-4 px-lg-5'>
        <Row>
          <Col lg="12" className='custom_slider'>
            <Carousel fade>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SliderOne}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>Elegant & Quality Fabric Curtains</h1>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SliderTwo}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h1>Premium Curtains & Blinds</h1>
                </Carousel.Caption>
              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SliderThree}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h1>Premium Quality</h1>
                </Carousel.Caption>
              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SliderFour}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h1>Premium Curtains & Blinds</h1>
                </Carousel.Caption>
              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SliderFive}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h1>Premium Curtains & Blinds</h1>
                </Carousel.Caption>
              </Carousel.Item>



            </Carousel>


<div onClick={handleClick} id="mouse-scroll" className=' d-none d-lg-block'>

  <div className="mouse ">
    <div className="mouse-in"></div>
  </div>
  <div>
      <span className="down-arrow-1"></span>
      <span className="down-arrow-2"></span>
      <span className="down-arrow-3"></span>

   </div>

</div>





          </Col>
        </Row>
      </Container>

      <section ref={ref}className='py-4'>
        <Container fluid className='px-4 px-lg-5'>
          <Row className='pe-2'>
            <Col md={5} lg={4}>
              <img src={AboutImage} alt="About" className='img-fluid w-100' />
            </Col>
            <Col md={7} lg={8} className='d-flex align-items-center home_about_section '>
              <div className="p-4">
                <h1 className="style01"> About <span>bharat furnishings</span></h1>
                <h3>
                bharat furnishings is a family owned business , established in 1971 with its flagship store in  Kemps Corner, Mumbai. 
                With retail expertise  of 50 years today we have our presence across India with 6 showrooms and more than 30,000 sq feet of retail space. 
                </h3>

                <h3>
                We take pride in representing a curated collection from leading national and international brands serving to all home furnishings needs. Bespoke quality, a vast and varied collection and our immaculate after sales service makes us the most referred with India's leading architects and interior designers. 
                </h3>

                <h3>
                We are trusted by  leading hotel chains, corporates and HNI clients, making us India's premium furnishing store.
                </h3>
          <div className='mt-3'> 
          
          <button className='btn btn-dark'>Read More</button>
          </div>

              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='py-4'>
        <Container fluid className='px-4 px-lg-5'>
          <Row >
            <Col lg="12" className='heading_divider text-center' >
              <h2>Explore Our Range</h2>
            </Col>

            {/* <Col lg="3" md="6">
            <Link to="/Fabric">  
              <Card className="bg-dark text-white rounded-0 border-0 mb-3">
            
              <Card.Img src={FabricImg} alt="Fabric" className='rounded-0' />
                <Card.ImgOverlay>
                  <Card.Title>
                     <h4 className='position-absolute bottom-0 start-50 translate-middle-x bg-white text-dark p-3 mb-0  pb-1 pt-2'>Fabric</h4>
                 
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
              </Link>
            </Col>



            <Col lg="3" md="6">
            <Link to="/Bed-Bath-and-Linen">
              <Card className="bg-dark text-white rounded-0 border-0 mb-3">
                <Card.Img src={BedBathLinen} alt="BedBathLinen" className='rounded-0 ' />
                <Card.ImgOverlay>
                  <Card.Title>
                
                    <h4 className='position-absolute bottom-0 start-50 translate-middle-x bg-white text-dark p-3 mb-0  pb-1 pt-2'>Bed & Bath Linen
                    </h4>
                 
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
              </Link>

            </Col>


            <Col lg="3" md="6">
            <Link to="/Blinds">
              <Card className="bg-dark text-white rounded-0 border-0 mb-3">
                <Card.Img src={BlindsLinen} alt="Blinds Linen" className='rounded-0' />
                <Card.ImgOverlay>
                  <Card.Title>
                
                    <h4 className='position-absolute bottom-0 start-50 translate-middle-x bg-white text-dark p-3 mb-0  pb-1 pt-2'>Blinds</h4>
      
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
              </Link>
            </Col>

            <Col lg="3" md="6">
            <Link to="/Rugs">
              <Card className="bg-dark text-white rounded-0 border-0 mb-3">
                <Card.Img src={RugsImg} alt="Rugs" className='rounded-0' />
                <Card.ImgOverlay>
                  <Card.Title>
                 
                    <h4 className='position-absolute bottom-0 start-50  translate-middle-x bg-white text-dark p-3 mb-0 pb-1 pt-2'>Rugs</h4>
               
                  </Card.Title>
                </Card.ImgOverlay>
              </Card>
              </Link>
            </Col> */}

              <div className='list-container'>
              <div className="vid-list position-relative">
              <Link to="/Fabric" onClick={scrollToTop}>  
              <img src={FabricImg} alt="" className='thumbnail' /> </Link>
              <h4 className="position-absolute bottom-0  bg-white text-dark p-3 mb-0  pb-1 pt-2">Fabric</h4>

              </div>

              <div className="vid-list position-relative">
              <Link to="/Wallpaper"  onClick={scrollToTop}>  
              <img src={WallpaeprImg} alt="" className='thumbnail' /> </Link>
              <h4 className='position-absolute bottom-0  bg-white text-dark p-2 mb-0 pb-1 pt-2'>Wallpaper</h4>
              </div>

              <div className="vid-list position-relative">
              <Link to="/Bed-Bath-and-Linen" onClick={scrollToTop}>
              <img src={BedBathLinen} alt="" className='thumbnail' /></Link>
              <h4 className='position-absolute bottom-0  bg-white text-dark p-2 mb-0  pb-1 pt-2'>Bed & Bath Linen</h4>
              </div>
 
              <div className="vid-list position-relative">
              <Link to="/Blinds" onClick={scrollToTop}><img src={BlindsLinen} alt="" className='thumbnail' /></Link>
              <h4 className='position-absolute bottom-0  bg-white text-dark p-2 mb-0  pb-1 pt-2'>Blinds</h4>
              </div>

              <div className="vid-list position-relative">
              <Link to="/Rugs" onClick={scrollToTop}><img src={RugsImg} alt="" className='thumbnail' /></Link>
              <h4 className='position-absolute bottom-0  bg-white text-dark p-2 mb-0 pb-1 pt-2'>Rugs</h4>
              </div>


              </div>

          </Row>
        </Container>
      </section >

      <section className='py-4'>
        <Container fluid className='px-4 px-lg-5 '>
          <Row className='mb-5'>
            <Col lg="12" className='heading_divider text-center '>
              <h2>Brands We Represent</h2>
              <ImageSlider />
            </Col>
          </Row>
        </Container>
      </section>


      {/* <section className=''>
        <Container fluid className='px-4 px-lg-5  '>
          <Row className='bg-light py-4 '>
            <Col lg="12" className='heading_divider text-center pb-4' >
              <h2>Testimonials</h2>
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section> */}




    </>
  )
}

export default Home
