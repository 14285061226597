import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import darkBanner from "../../images/dark-img.jpg";
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import '../About/About.css';
import LocationSlider from './LocationSlider';
const About = () => {

    useEffect(() => {
        document.title = "About - bharat furnishings"
    }, []);




    return (
        <React.Fragment>

            <Container fluid className='px-4 px-lg-5'>
                <Row>
                    <Col lg="12" className='custom_slider inner_banner position-relative'>
                        <img src={darkBanner} alt="About" className='img-fluid w-100' />
                        <div className="position-absolute top-50 start-50 translate-middle ">
                            <h1 className="text-white text-center">About </h1>
                            {/* <nav aria-label="breadcrumb" className="d-none d-lg-block">
                                <ol className="breadcrumb text-white align-items-center  ">
                                    <li className="breadcrumb-item "><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active " aria-current="page">About</li>
                                </ol>
                            </nav> */}
                        </div>

                    </Col>
                    <Col lg={12}>
                <nav aria-label="breadcrumb" className=" mt-4 mb-4 ">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> <FaHome className=' lh-sm pb-1'/>  Home</Link></li>
                                    <li className="breadcrumb-item  text-dark " aria-current="page">About</li>
                                </ol>
                            </nav>
                            <hr />
                </Col>

                    <Col lg="12" className='pt-3'>
                        <p>
                        With a vision to start a one stop store for all furnishing needs, Bharat furnishings flagship store was launched in the year 1971 at Kemps Corner Mumbai.
                                               </p>

                                               <p>   
                                                We represent India’s leading and international brands, making  leading hotel chains, 
                                                corporates and HNI our esteemed clients for years. </p>

                    </Col>

   



                </Row>
            </Container>





            <Container fluid className='px-4 px-lg-5 py-4 mb-4'>
                <Row>
                    <Col lg="12">
                        <LocationSlider />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default About
