import React from "react";
import Slider from "react-slick";
import Address from "./Address";

let data = [
  {
    title: "Mumbai - Kemp's Corner",
    disc: "India House, Cumballa Hill, Kemps Corner, Mumbai - 400 036. Maharashtra, India.",
  },
  {
    title: "Mumbai Vile Parle",
    disc: "Crystal Classic, S. V. Road, Next to Golden Tabacco, Vile Parle (W), Mumbai-400 058. Maharashrta, India.",
  },
  {
    title: "Thane",
    disc: "Sun Magnetica, Service Road, Lousie Wadi, Near RTO, Off Eastern Express Highway, Thane (west) - 400406",
  },
  {
    title: "Pune",
    disc: "Sai-Ikon, Survey 84/2, 10, BanerRoad, Near Sakal Nagar, Pune, Maharashtra, India.",
  },

  {
    title: "Surat",
    disc: "Shop No. 5, Union Heights, Maharana Pratap Road, Piplod, Surat, Gujarat - 395007.",
  },
  {
    title: "Ahmedabad",
    disc: "Sarthik II, opp Rajpath Club, Sarkhej Gandhi Nagar H/W, Ahmedabad - 380015. Gujrat. India.",
  },
];

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  autoplay: true,

  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LocationSlider = () => {
  let sliderData = "";
  sliderData = data.map((item, i) => <Address item={item} key={i} />);

  return (
    <React.Fragment>
      <Slider {...settings}>{sliderData}</Slider>
    </React.Fragment>
  );
};

export default LocationSlider;
