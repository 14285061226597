import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaInstagram } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaSitemap } from 'react-icons/fa';
import '../Header/Header.css';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <Container fluid className='bg-light px-2 px-lg-5'>
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-between py-2">
              <div className='fs-5'> 
              <a href="mailto:info@bharatfurnishings.com" className='pe-1' target="_blank" rel='noreferrer'>   <FaEnvelope /> info@bharatfurnishings.com </a>
                
              </div>
              <div className='social_top_icon'>
               
                <a href="https://www.instagram.com/bharatfurnishings/" className='pe-1' target="_blank" rel='noreferrer'> <FaInstagram /></a>
             <NavLink to="/Sitemap"> <FaSitemap /></NavLink>
              </div>

            </div>

          </Col>

        </Row>
      </Container>



    </>
  )
}

export default Header
