import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BenBathLinenProduct from '../BedBathLinenProduct/BedBathLinenProduct';
import BenBathLinen_01 from "../../images/Ben_Bath_Linen-01.jpg";
import BenBathLinen_02 from '../../images/Ben_Bath_Linen-02.jpg'
import BenBathLinen_03 from '../../images/Bed-Bath-Linen-03.jpg'
import BenBathLinen_04 from '../../images/Ben_Bath_Linen-04.jpg'
import BenBathLinen_05 from '../../images/Ben_Bath_Linen-05.jpg'
import BenBathLinen_06 from '../../images/Ben_Bath_Linen-06.jpg'
import BenBathLinen_07 from '../../images/Ben_Bath_Linen-07.jpg'
import BenBathLinen_08 from '../../images/Ben_Bath_Linen-08.jpg'
import BenBathLinen_09 from '../../images/Ben_Bath_Linen-09.jpg'
import BenBathLinen_10 from '../../images/Bed-Bath-Linen-05.jpg'
import BenBathLinen_11 from '../../images/Ben_Bath_Linen-11.jpg'
import BenBathLinen_12 from '../../images/Ben_Bath_Linen-12.jpg'
import darkBanner from "../../images/dark-img.jpg";


const BedBathLinen = () => {

    const BenBathLinenImages = [
        {
          img:BenBathLinen_01,
          title:"Bed & Bath Linen 1"
        },
        {
            img:BenBathLinen_02,
            title:"Bed & Bath Linen 2"

          },
          {
            img:BenBathLinen_03,
            title:"Bed & Bath Linen 3"
          },
          {
            img:BenBathLinen_04,
            title:"Bed & Bath Linen 4"

          },
           {
            img:BenBathLinen_05,
            title:"Bed & Bath Linen 5"

          },
          {
            img:BenBathLinen_06,
            title:"Bed & Bath Linen 6"

          },
          {
            img:BenBathLinen_07,
            title:"Bed & Bath Linen 7"

          },
          {
            img:BenBathLinen_08,
            title:"Bed & Bath Linen 8"

          },
           {
            img:BenBathLinen_09,
            title:"Bed & Bath Linen 9"

          },
          {
            img:BenBathLinen_10,
            title:"Bed & Bath Linen 10"

          },
          {
            img:BenBathLinen_11,
            title:"Bed & Bath Linen 11"

          },
          {
            img:BenBathLinen_12,
            title:"Bed & Bath Linen 12"
          },
      ]



    useEffect(() => {
        document.title = "Bed Bath Linen - bharat furnishings";

    }, []);



    return (
        <React.Fragment>
        <Container fluid className='px-4 px-lg-5'>
                    <Row>
                    <Col lg="12" className='custom_slider inner_banner position-relative'>
                            <img src={darkBanner} alt="About" className='img-fluid w-100' />
                            <div className="position-absolute top-50 start-50 translate-middle ">
                                <h1 className="text-white text-center">Bed & Bath Linen </h1>
                               
                            </div>
    
                        </Col>
    
                    <Col lg={12}>
                    <nav aria-label="breadcrumb" className=" mt-4 mb-4 ">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"> <FaHome className=' lh-sm pb-1'/>  Home</Link></li>
                                        <li className="breadcrumb-item  text-dark " aria-current="page">Bed & Bath Linen</li>
                                    </ol>
                                </nav>
                    </Col>
    

            <BenBathLinenProduct  BenBathLinenImages={BenBathLinenImages}/>
                    </Row>
         
                </Container>
            </React.Fragment>
    )
}

export default BedBathLinen
