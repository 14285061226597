import React, { useEffect } from 'react'

const Projects = () => {


    useEffect(() => {
        document.title = "Projects - bharat furnishings";
    }, []);


    return (
        <div>
            <h1>Projects</h1>
        </div>
    )
}

export default Projects
