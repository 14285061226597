import NavbarComp from "./NavbarComp";
function App() {
  return (
    <>
      <NavbarComp />
    </>
  );
}

export default App;
