import { useState } from 'react'
import Col from 'react-bootstrap/Col';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';


const BlindsProducts = ({blindsImages}) => {
  
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( blindsImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === blindsImages.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }


  return (
    <>





{openModal && 
        <div className='sliderWrap'>
          <AiFillCloseCircle className='btnClose fs-1' onClick={handleCloseModal}  />
          <BsFillArrowLeftCircleFill className='btnPrev fs-1' onClick={prevSlide} />
          <BsFillArrowRightCircleFill className='btnNext fs-1' onClick={nextSlide} />
          <div className='fullScreenImage'>
            <img src={blindsImages[slideNumber].img}  alt='' title={blindsImages[slideNumber].title}  />
          </div>
        </div>
      }



  
      
        {
          blindsImages && blindsImages.map((slide,index) => {
            return(
              <Col xs={12} md={6} lg={3} key={index} className='mb-4' >  
               <img src={slide.img} alt='' className='img-fluid single' onClick={ () => handleOpenModal(index) } />
              {/* <p className='pt-2 mb-0 text-center'> {slide.title}</p> */}
            </Col>
             
            )
          })
        }



    





    </>
  )
}

export default BlindsProducts
