import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "././images/BF-logo.jpg";
import './NavbarComp.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Fabric from './Components/Fabric/Fabric';
import BedBathLinen from './Components/BedBathLinen/BedBathLinen';
import Blinds from './Components/Blinds/Blinds';
import Projects from './Components/Projects/Projects';
import Rugs from './Components/Rugs/Rugs';
import Contact from './Components/Contact/Contact';
import Sitemap from './Components/Sitemap/Sitemap';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Wallpaper from './Components/Wallpaper/Wallpaper';

const NavbarComp = () => {


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    };



    return (
        <> <Router>

            <Header />
            <Navbar collapseOnSelect expand="lg" className="bg-white sticky-top border-bottom" >
                <Container fluid className='px-2 px-lg-5'>
                    <Navbar.Brand as={Link} to="/"  className='d-flex'>
                        <img
                            width={60}
                            alt=""
                            src={Logo}
                            className="d-inline-block align-center me-3"
                        />
                        <p className="logo">bharat furnishings
                            <span className="d-block small mt-1">Luxurious Living</span>
                        </p>
                    </Navbar.Brand>

                    <Navbar.Toggle className='text-white' aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                        >
                            <Nav.Link as={Link} to="/" activeclassname="active" eventKey="/" onClick={scrollToTop}>Home</Nav.Link>
                            <Nav.Link as={Link} to="/About" activeclassname="active" eventKey="/About" onClick={scrollToTop}>About</Nav.Link>
                            <Nav.Link as={Link} to="/Fabric" activeclassname="active" eventKey="/Fabric" onClick={scrollToTop}>Fabric</Nav.Link>
                            <Nav.Link as={Link} to="/Wallpaper" activeclassname="active" eventKey="/Wallpaper" onClick={scrollToTop}>Wallpaper</Nav.Link>
                            <Nav.Link as={Link} to="/Bed-Bath-and-Linen" activeclassname="active" eventKey="/Bed-Bath-and-Linen" onClick={scrollToTop}>Bed & Bath Linen</Nav.Link>
                            <Nav.Link as={Link} to="/Blinds" activeclassname="active" eventKey="/Blinds" onClick={scrollToTop}>Blinds</Nav.Link>
                            <Nav.Link as={Link} to="/Rugs" activeclassname="active" eventKey="/Rugs" onClick={scrollToTop}>Rugs</Nav.Link>
                          
                            {/* <Nav.Link as={Link} to="/Projects" activeclassname="active" eventKey="/Projects" onClick={scrollToTop}>Projects</Nav.Link> */}

                            <Nav.Link as={Link} to="/Contact" activeclassname="active" eventKey="/Contact" onClick={scrollToTop}>Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Routes>
                <Route path="/" exact element={<Home />} ></Route>
                <Route path="/About" exact element={<About />}></Route>
                <Route path="/Fabric" exact element={<Fabric />}></Route>
                <Route path="/Wallpaper" exact element={<Wallpaper />}></Route>
                <Route path="/Bed-Bath-and-Linen" exact element={<BedBathLinen />}></Route>
                <Route path="/Blinds" exact element={<Blinds />}></Route>
                <Route path="/Rugs" exact element={<Rugs />}></Route>
                <Route path="/Projects" exact element={<Projects />}></Route>
                <Route path="/Contact" exact element={<Contact />}></Route>
                <Route path="/Sitemap" exact element={<Sitemap />}></Route>
            </Routes>


            <Footer />
        </Router>


        </>
    )
}

export default NavbarComp
