import React from 'react'
import Slider from "react-slick";
import Arezzo from "../../images/arezzo-brand.jpg";
import Persen from "../../images/persen-brand.jpg";
import Rasaya from "../../images/rasaya-brand.jpg";
import RRDecor from "../../images/rr-decor-brand.jpg";
import Sketch from "../../images/sketch-brand.jpg";
import Vaya from "../../images/vaya-brand.jpg";

var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const ImageSlider = () => {




    return (
        <React.Fragment>

            <Slider {...settings} className='ms-2 me-2'>
                <div>
                    <img src={Arezzo} alt="Arezzo" className='img-fluid w-100' />
                </div>
                <div>
                    <img src={Persen} alt="Persen" className='img-fluid w-100' />
                </div>
                <div>
                    <img src={Rasaya} alt="Rasaya" className='img-fluid w-100' />
                </div>
                <div>
                    <img src={RRDecor} alt="RRDecor" className='img-fluid w-100' />
                </div>
                <div>
                    <img src={Sketch} alt="Sketch" className='img-fluid w-100' />
                </div>
                <div>
                    <img src={Vaya} alt="Vaya" className='img-fluid w-100' />
                </div>



            </Slider>



        </React.Fragment>
    )
}

export default ImageSlider
