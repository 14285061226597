import React, { useEffect } from 'react'
import './Fabric.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FabricProduct from '../FabricProduct/FabricProduct';
import darkBanner from "../../images/dark-img.jpg";

import Fab_pro01 from '../../images/F1580-01.jpg'
import Fab_pro02 from '../../images/fabric01.jpg'
import Fab_pro03 from '../../images/F1582-03.jpg'
import Fab_pro04 from '../../images/F1582-04.jpg'
import Fab_pro05 from '../../images/F1582-05.jpg'
import Fab_pro06 from '../../images/fabric02.jpg'
import Fab_pro07 from '../../images/F1582-07.jpg'
import Fab_pro08 from '../../images/fabric03.jpg'
import Fab_pro09 from '../../images/fabric04.jpg'
import Fab_pro10 from '../../images/fabric05.jpg'
import Fab_pro11 from '../../images/F1582-11.jpg'
import Fab_pro12 from '../../images/fabric06.jpg'
import Fab_pro13 from '../../images/fabric07.jpg'
import Fab_pro14 from '../../images/fabric08.jpg'
import Fab_pro15 from '../../images/fabric09.jpg'
import Fab_pro16 from '../../images/fabric10.jpg'
import Fab_pro17 from '../../images/fabric11.jpg'
import Fab_pro18 from '../../images/fabric12.jpg'
import Fab_pro19 from '../../images/fabric13.jpg'



import Fab_pro20 from '../../images/fabric14.jpg'
import Fab_pro21 from '../../images/fabric15.jpg'
import Fab_pro22 from '../../images/fabric16.jpg'
import Fab_pro23 from '../../images/fabric17.jpg'
import Fab_pro24 from '../../images/fabric18.jpg'
import Fab_pro25 from '../../images/fabric19.jpg'
import Fab_pro26 from '../../images/fabric20.jpg'
import Fab_pro27 from '../../images/fabric21.jpg'
import Fab_pro28 from '../../images/fabric22.jpg'
import Fab_pro29 from '../../images/fabric23.jpg'
import Fab_pro30 from '../../images/fabric24.jpg'

import Fab_pro31 from '../../images/fabric25.jpg'
import Fab_pro32 from '../../images/fabric26.jpg'
import Fab_pro33 from '../../images/fabric27.jpg'
import Fab_pro34 from '../../images/fabric28.jpg'
import Fab_pro35 from '../../images/fabric29.jpg'
import Fab_pro36 from '../../images/fabric30.jpg'







const Fabric = () => {

    const galleryImages = [
      {img:Fab_pro01,title:"Fabrick 1"},
        {img:Fab_pro02, title:"Fabrick 1"},
        {img:Fab_pro03,title:"Fabrick 3"},

        {img:Fab_pro06,title:"Fabrick 3"},
        {img:Fab_pro07,title:"Fabrick 3"},
        {img:Fab_pro08,title:"Fabrick 3"},
        {img:Fab_pro09,title:"Fabrick 3"},
        {img:Fab_pro10,title:"Fabrick 3"},
        {img:Fab_pro11,title:"Fabrick 3"},
        {img:Fab_pro12,title:"Fabrick 3"},
        {img:Fab_pro13,title:"Fabrick 3"},
        {img:Fab_pro04,title:"Fabrick 3"},
        {img:Fab_pro14,title:"Fabrick 3"},
        {img:Fab_pro15,title:"Fabrick 3"},
        {img:Fab_pro16,title:"Fabrick 3"},
        {img:Fab_pro17,title:"Fabrick 3"},
        {img:Fab_pro18,title:"Fabrick 3"},
        {img:Fab_pro19,title:"Fabrick 3"},

        {img:Fab_pro20,title:"Fabrick 3"},
        {img:Fab_pro21,title:"Fabrick 3"},
        {img:Fab_pro22,title:"Fabrick 3"},
        {img:Fab_pro23,title:"Fabrick 3"},
        {img:Fab_pro24,title:"Fabrick 3"},
        {img:Fab_pro25,title:"Fabrick 3"},
        {img:Fab_pro26,title:"Fabrick 3"},
        {img:Fab_pro27,title:"Fabrick 3"},
        {img:Fab_pro28,title:"Fabrick 3"},
        {img:Fab_pro05,title:"Fabrick 3"},
        {img:Fab_pro29,title:"Fabrick 3"},
        {img:Fab_pro30,title:"Fabrick 3"},
        {img:Fab_pro31,title:"Fabrick 3"},
        {img:Fab_pro32,title:"Fabrick 3"},
        {img:Fab_pro33,title:"Fabrick 3"},
        {img:Fab_pro34,title:"Fabrick 3"},
        {img:Fab_pro35,title:"Fabrick 3"},
        {img:Fab_pro36,title:"Fabrick 3"},
      ]

    useEffect(() => {
        document.title = "Fabric - bharat furnishings";
    }, []);

    return (
    <React.Fragment>
    <Container fluid className='px-4 px-lg-5'>
                <Row>
                <Col lg="12" className='custom_slider inner_banner position-relative'>
                        <img src={darkBanner} alt="About" className='img-fluid w-100' />
                        <div className="position-absolute top-50 start-50 translate-middle ">
                            <h1 className="text-white text-center">Fabric </h1>
                           
                        </div>

                    </Col>

                <Col lg={12}>
                <nav aria-label="breadcrumb" className=" mt-4 mb-4 ">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> <FaHome className=' lh-sm pb-1'/>  Home</Link></li>
                                    <li className="breadcrumb-item  text-dark " aria-current="page">Fabric</li>
                                </ol>
                            </nav>
                </Col>

                <FabricProduct galleryImages={galleryImages}/>
                </Row>




 











   </Container>
  </React.Fragment>
    )
}

export default Fabric
